import { Button } from './Button'
const Terms = () => {
  return (
    <div className='flex flex-col gap-2'>
      <Button
        variant={'link'}
        onClick={() => window.open('/oferta', '_blank')}
        rel='noreferrer'
        className='flex justify-center w-full text-sm text-text-tertiary hover:text-text-primary'
      >
        Публичная оферта
      </Button>
      <div className='flex flex-col text-xs text-center text-text-tertiary'>
        <span>БУ ВО «Сургутский государственный университет»</span>
        <span>628412, Ханты- Мансийский автономный округ-Югра, г. Сургут, пр. Ленина, 1</span>
        <span>ОКПО 27387694 / ОКТМО 71876000001 / КБК 00000000000000000130</span>
        <span>ИНН 8602200001 / КПП 860201001 / БИК 007162163</span>
        <span>Телефоны: (3462) 76-29-00, 76-29-29</span>
        <span>Электронная почта: dpo@surgu.ru </span>
      </div>
    </div>
  )
}

export default Terms
